

<style scoped>
#remote-video-canvas > div {
  display: flex;
  align-items: center;
  justify-items: center;
  background-color: black;
}

#remote-video-canvas {
  display: flex;
  align-items: center;
  justify-items: center;
  animation: all 1s;
  background-color: black;
}

#local-video-canvas > div {
  border-radius: 8px !important;
}

.local-video {
  width: 120px;
  height: 120px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 99;
}

.waiting-call {
  z-index: 12;
  width: 100%;
  height: 150px;
  position: absolute;
  top: 0;
  background: transparent;
}

.video-body {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: none;
  display: flex;
  justify-items: center;
}

.video-btn-group {
  z-index: 12;
  width: 100%;
  position: absolute;
  bottom: 20px;
  background: transparent;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.close-call-container {
  z-index: 12;
  width: 100%;
  position: absolute;
  bottom: 75px;
  background: transparent;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
<template>
  <v-app id="unity_video_call">
    <v-main>
      <div style="height: 100%; width: 100%;">
        <div class="video-header">
          <div v-show="callAnswered" id="local-video-canvas" class="local-video grey rounded-lg"></div>
        </div>
        <div class="video-body">
          <div class="waiting-call" v-if="!callAnswered" >
            <v-card-title class="white--text justify-center">{{ videoCall.participant.contact.name }}</v-card-title>
            <v-card-text class="white--text text-center">
              <v-text-field
                id="callee_link"
                :title="
                  $capitalize($tc('model.video_call_send_link_participant'))
                "
                dense
                solo
                :value="videoCall.callee_invite_link"
                readonly
              >
                <template v-slot:append>
                  <v-btn icon @click="copyCalleeLink">
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                  <v-btn icon @click="openNumberDialog">
                    <v-icon>mdi-send</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              {{ $capitalize($tc("model.video_call_waiting_participant")) }}
            </v-card-text>
            <v-card-text class="white--text text-center">
              <v-progress-circular indeterminate rotate />
            </v-card-text>
          </div>
          <div class="close-call-container">
            <v-btn @click="leaveCall" class="mx-2 close-call-button" fab dark color="red">
              <v-icon dark>mdi-phone-hangup</v-icon>
            </v-btn>
          </div>
        </div>
        <div
          id="remote-video-canvas"
          class="rounded-lg fill-height black"
        ></div>
        <div class="video-btn-group">
          <v-btn 
            class="mx-2 action-button" 
            icon 
            @click="takeScreenshot" 
            :disabled="loading_screenshot" 
            :loading="loading_screenshot" 
            color="white"
          >
            <v-icon>mdi-camera</v-icon>
          </v-btn>
          <v-btn @click="toggleVideoCamera()" class="mx-2 action-button" icon :value="camera_off" color="white">
            <v-icon>{{ camera_off ? 'mdi-video' : 'mdi-video-off' }}</v-icon>
          </v-btn>
          <v-btn @click="toggleMute()" class="mx-2 action-button" icon color="white">
            <v-icon>{{ mic_off ? 'mdi-microphone' : 'mdi-microphone-off' }}</v-icon>
          </v-btn>
        </div>
        
        <v-dialog v-model="participant_contact_dialog" max-width="400px">
          <v-card>
            <v-card-title>
              {{ $capitalize($tc("model.video_call_send_invitation")) }}
            </v-card-title>
            <v-card-text>
              <v-select
                :label="
                  $capitalize($tc('model.video_call_participant_number'))
                "
                v-model="participant_contact"
                :items="contactNumbers"
                item-text="number"
                return-object
                append-outer-icon="mdi-account-edit"
                @click:append-outer="openContactDialog"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text @click="participant_contact_dialog = false">{{ $capitalize($tc("model.video_call_cancel_button")) }}</v-btn>
              <v-btn text @click="sendWhatsappMessage">
                <v-icon color="green" left>mdi-whatsapp</v-icon>{{ $capitalize($tc("model.video_call_whatsapp_button")) }}
              </v-btn>
              <v-btn text @click="sendCalleeLink" :loading="loading_message_sent">
                <v-icon color="grey" left>mdi-message</v-icon>{{ $capitalize($tc("model.video_call_message_button")) }}
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-dialog v-model="contact_dialog" max-width="600px">
            <contact-form @cancel="contact_dialog = false" @submit="handleSubmitContactDialog" :id="contact_id"></contact-form>
          </v-dialog>
        </v-dialog>
      </div>
    </v-main>
    <v-snackbar
      v-model="snackbar.active"
      :timeout="5000"
      left
      bottom
      :color="snackbar.color || ''"
    >
      {{ snackbar.text }}
      <!-- <v-btn color="white" text @click="closeSnack">Fechar</v-btn> -->
    </v-snackbar>
  </v-app>
</template>

<script>
import {
  joinCall,
  leaveCall,
  takeRemoteUserScreenshot,
  toggleVideo,
  toggleMicrophone
} from "@/services/agora";
import {
  joinRemoteAnalysisUnity,
  endRemoteAnalysisUnity,
  sendInviteLink
} from "@/services/remote_analysis";
import {
  store as storeAttachment,
  attachment_categories,
} from "@/services/attachment";
import {
  getImageFromVideoElement,
  blobToFile,
  dataURItoBlob,
} from "@/tools/image";

import ContactForm from "../components/forms/ContactForm";

export default {
  name: "unity-video-call",
  components: {
    ContactForm
  },
  data: () => ({
    contact_dialog: false,
    contact_id: null,
    participant_contact_dialog: false,
    participant_contact: null,
    callAnswered: false,
    videoContainer: null,
    loading_screenshot: false,
    loading_message_sent: false,
    camera_off: false,
    mic_off: false,
    remoteUser: {
      uid: null,
      trackId: null,
      playerContainer: null,
      callHasBeenAnswered: () => ({}),
      callHasBeenLeft: () => ({}),
      screenshotCount: 0,
    },
    localUser: {
      uid: null,
      playerContainer: null,
      tokenHasBeenExpired: () => ({}),
    },
  }),
  methods: {
    openNumberDialog() {
      this.contact_id = this.videoCall.participant.contact.id;
      this.participant_contact = { type: 'contact1', number: this.videoCall.participant.contact.contact1 };
      this.participant_contact_dialog = true;
    },
    openContactDialog() {
      this.contact_id = this.videoCall.participant.contact.id;
      this.contact_dialog = true;
    },
    handleSubmitContactDialog(payload) {
      this.$store.commit('setVideoCall', {
        ...this.videoCall,
        participant: {
          ...this.videoCall.participant,
          contact: { ...payload }
        }
      });
      localStorage.setItem('video_call', JSON.stringify(this.$store.getters.getVideoCall));

      if (this.participant_contact) {
        this.participant_contact.number = payload[this.participant_contact.type]
      };
        
      this.contact_id = null;
      this.contact_dialog = false;
    },
    sendWhatsappMessage() {
      const message = `Peritagem referente a Lead ${this.videoCall.lead_number} - Convite para peritagem remota, clique no link para iniciá-la: ${this.videoCall.callee_invite_link}`;
      const link = `https://wa.me/${this.participant_contact.number}?text=${encodeURIComponent(message)}`;

      window.open(link, '_blank');
    },
    async sendCalleeLink() {
      this.loading_message_sent = true;
      try {
        await sendInviteLink(this.videoCall.id, { participant_number: this.participant_contact.number });
        this.$store.commit('sendMessage', {
          text: 'Mensagem enviada com sucesso para ' + this.participant_contact.number
        });
      } catch (e) {
        this.$store.commit('sendMessage', {
          text: 'Falha no envio da mensagem'
        });
      } finally {
        this.loading_message_sent = false;
      }
    },
    copyCalleeLink() {
      const copyText = document.querySelector("#callee_link");
      copyText.select();
      document.execCommand("Copy");
      this.$store.commit("sendMessage", {
        text: `Texto copiado`,
      });
    },
    async toggleVideoCamera() {
      await toggleVideo();
      this.camera_off = !!!this.camera_off;
    },
    async toggleMute() {
      await toggleMicrophone();
      this.mic_off = !!!this.mic_off;
    },
    async callHasBeenAnswered() {
      this.callAnswered = true;
      await joinRemoteAnalysisUnity(this.videoCall.id);
    },
    async callHasBeenLeft() {
      this.callAnswered = false;
      this.$store.commit("sendMessage", {
        text: "Participante deixou a chamada",
        color: "orange",
      });
    },
    async leaveCall() {
      this.$store.commit("setVideoCall", {
        ...this.videoCall,
        isActive: false,
      });
      localStorage.removeItem('video_call');
      await leaveCall();

      if (this.callAnswered) {
        await endRemoteAnalysisUnity(this.videoCall.id);
      }
      this.$store.commit("sendMessage", {
        text: "Chamada finalizada",
        color: "green",
      });
      this.$router.replace({ name: "video_call_ended" });
    },
    async takeScreenshot() {
      this.remoteUser.screenshotCount++;
      const blob = getImageFromVideoElement(
        document.querySelector("video#video_" + this.remoteUser.trackId)
      );

      const fileName =
        this.videoCall.channel + "_" + this.remoteUser.screenshotCount;
      const file = blobToFile(blob, fileName);

      let data = new FormData();
      data.append("attachment", file);
      data.append("description", fileName);
      data.append("category_id", attachment_categories.remote_analysis);

      this.loading_screenshot = true;
      try {
        await storeAttachment(this.videoCall.lead_number, data);
        this.$store.commit("sendMessage", {
          text: `A imagem ${fileName} foi salva na gestão documental`,
          color: "green",
        });
      } catch (e) {
        this.$store.commit("sendMessage", {
          text: "Não foi possível salvar a imagem capturada",
          color: "red",
        });
      } finally {
        this.loading_screenshot = false;
      }
    },
  },
  computed: {
    videoCall: function () {
      return this.$store.getters.getVideoCall;
    },
    user: function() {
      return this.$store.getters['auth/getUser'];
    },
    contactNumbers: function () {
      const contact1 = { type: 'contact1', number: this.videoCall.participant.contact.contact1 };
      const contact2 = { type: 'contact2', number: this.videoCall.participant.contact.contact2 };

      const contacts = [];

      if (contact1.number)
        contacts.push(contact1);

      if (contact2.number)
        contacts.push(contact2);

      return contacts;      
    },
    snackbar() {
      return this.$store.getters.getSnackbar;
    }
  },
  mounted() {
    this.remoteUser.playerContainer = document.querySelector(
      "#remote-video-canvas"
    );
    this.remoteUser.callHasBeenAnswered = this.callHasBeenAnswered;
    this.remoteUser.callHasBeenLeft = this.callHasBeenLeft;

    this.localUser.playerContainer = document.querySelector(
      "#local-video-canvas"
    );

    this.localUser.uid = this.user?.sub || 0;
    this.localUser.tokenHasBeenExpired = this.leaveCall;

    joinCall(
      this.videoCall.channel,
      this.videoCall.token_caller,
      this.localUser,
      this.remoteUser
    );
  },
  created() {
    this.$store.commit("setVideoCall", JSON.parse(localStorage.getItem('video_call')));
  }
};
</script>