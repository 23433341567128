export function getImageFromVideoElement(videoElement) {
  const canvas = document.createElement("canvas");
  // scale the canvas accordingly
  canvas.width = videoElement.videoWidth;
  canvas.height = videoElement.videoHeight;
  // draw the video at that frame
  canvas.getContext('2d')
    .drawImage(videoElement, 0, 0, canvas.width, canvas.height);
  // convert it to a usable data URL
  return dataURItoBlob(canvas.toDataURL());
}

export function getUriFromVideoElement(videoElement) {
  const canvas = document.createElement("canvas");
  // scale the canvas accordingly
  canvas.width = videoElement.videoWidth;
  canvas.height = videoElement.videoHeight;
  // draw the video at that frame
  canvas.getContext('2d')
    .drawImage(videoElement, 0, 0, canvas.width, canvas.height);
  // convert it to a usable data URL
  return canvas.toDataURL();
}

export function dataURItoBlob(dataURI) {
    let byteString;
    let mimeString;
    let ia;

    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = encodeURI(dataURI.split(',')[1]);
    }
    // separate out the mime component
    mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type:mimeString});
}

export function blobToFile(blob, fileName){
    const file = new File([blob], fileName, {lastModified: new Date()});
    return file;
}