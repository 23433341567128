import Vue from "vue";

export async function startRemoteAnalysis(payload) {
  const response = await Vue.prototype.$http.post("remote_analysis/start", payload);

  return response.data;
}

export async function joinRemoteAnalysisUnity(call_id) {
  const response = await Vue.prototype.$http.post("remote_analysis/unity/join/" + call_id);

  return response.data;
}
  
export async function endRemoteAnalysisUnity(call_id) {
  const response = await Vue.prototype.$http.post("remote_analysis/unity/end/" + call_id);

  return response.data;
}
  
export async function sendInviteLink(call_id, payload) {
  const response = await Vue.prototype.$http.post("remote_analysis/invite_link/" + call_id, payload);

  return response.data;
}

export const term_of_agreement = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ac lacinia urna. Cras rutrum molestie diam, non convallis purus hendrerit ut. Aliquam a enim sapien. Integer tellus ante, posuere eu turpis eget, sagittis ullamcorper ex. Pellentesque felis enim, vestibulum ut enim imperdiet, interdum euismod tellus. Mauris elementum lectus eu feugiat vestibulum. Curabitur vitae euismod felis.

In aliquet mauris ex, convallis tempus eros elementum a. Aenean in odio vel metus elementum accumsan vehicula feugiat sapien. Proin condimentum suscipit augue in luctus. Maecenas fermentum tortor non leo elementum bibendum. Donec vel ullamcorper est, vitae auctor nibh. Fusce ante dolor, fermentum sit amet felis eu, egestas venenatis mi. Quisque turpis neque, tempor sit amet mauris sit amet, suscipit varius urna. Duis iaculis turpis quis diam congue semper. Curabitur eleifend urna ut dolor gravida commodo ac vel ligula. Duis id ante magna. Fusce auctor nisi at erat semper faucibus. Cras eu euismod ipsum, ac laoreet erat.

Integer consequat nisl tincidunt, tempus libero in, aliquet ante. Nam porta nibh sit amet purus cursus mollis. Aenean ultricies dui mollis diam varius elementum. Suspendisse eget tincidunt odio. Vestibulum eu mollis erat. Cras vitae molestie massa, a interdum eros. Integer rutrum odio metus, eget semper massa suscipit quis. Sed volutpat urna nibh, consequat mattis neque vehicula eget. Vestibulum vel lectus dignissim, condimentum nulla sit amet, tristique diam. Vestibulum non bibendum sem. Integer pellentesque ipsum non tincidunt ullamcorper. Integer id lobortis neque. Praesent sed velit efficitur, maximus mi vel, tincidunt nisl. Donec sit amet lectus odio. Cras porttitor augue nec tellus lobortis scelerisque. Interdum et malesuada fames ac ante ipsum primis in faucibus.`
