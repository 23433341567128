import AgoraRTC from "agora-rtc-sdk-ng";

const AGORA_APP_ID =
  /*process.env.AGORA_API_ID*/ "82ead256d1474b709ffa59d16284afc7";

let client; // Agora client
const localTracks = {
  videoTrack: null,
  audioTrack: null
};
let remoteUsers = {};

export async function joinCall(channel, token, localUser, remoteUser) {
  // create Agora client
  client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });

  // add event listener to play remote tracks when remote user publishs.
  client.on("user-published", handleUserPublished(remoteUser));
  client.on("user-unpublished", handleUserUnpublished(remoteUser));

  client.on("user-joined", async function() {
    await remoteUser.callHasBeenAnswered();
  });

  client.on("user-left", async function() {
    await remoteUser.callHasBeenLeft();
  });

  client.on("token-privilege-did-expire", async function() {
    await localUser.tokenHasBeenExpired();
  });

  // join a channel and create local tracks, we can use Promise.all to run them concurrently
  [
    localUser.uid,
    localTracks.audioTrack,
    localTracks.videoTrack
  ] = await Promise.all([
    // join the channel
    client.join(AGORA_APP_ID, channel, token, localUser.uid),
    // create local tracks, using microphone and camera
    AgoraRTC.createMicrophoneAudioTrack(),
    AgoraRTC.createCameraVideoTrack({
      facingMode: "environment",
      optimizationMode: "detail"
    })
  ]);

  // play local video track
  localTracks.videoTrack.play(localUser.playerContainer);

  // publish local tracks to channel
  await client.publish(Object.values(localTracks));
}

function handleUserPublished(remoteUser) {
  return (user, mediaType) => {
    const id = user.uid;
    remoteUsers[id] = user;
    subscribe(user, mediaType, remoteUser);
  };
}

function handleUserUnpublished() {
  return user => {
    const id = user.uid;
    delete remoteUsers[id];
  };
}

async function subscribe(user, mediaType, remoteUser) {
  remoteUser.uid = user.uid;
  // subscribe to a remote user
  await client.subscribe(user, mediaType);

  if (mediaType === "video") {
    user.videoTrack.play(remoteUser.playerContainer);
    remoteUser.trackId = user.videoTrack.getTrackId();
  }

  if (mediaType === "audio") {
    user.audioTrack.play();
  }
}

export async function leaveCall() {
  localTracks.videoTrack.stop();
  localTracks.videoTrack.close();
  localTracks.videoTrack = undefined;

  localTracks.audioTrack.stop();
  localTracks.audioTrack.close();
  localTracks.audioTrack = undefined;

  // remove remote users and player views
  remoteUsers = {};

  // leave the channel
  await client.leave();
  console.log("client leaves channel success");
}

export async function switchCamera() {
  const currentDeviceId = localTracks.videoTrack
    .getMediaStreamTrack()
    .getSettings()?.deviceId;
  const unusedCameras = (await AgoraRTC.getCameras()).filter(
    cam => cam.deviceId != currentDeviceId
  );

  if (unusedCameras.length > 0) {
    localTracks.videoTrack.setDevice(unusedCameras[0]?.deviceId);
    return true;
  } else {
    return false;
  }
}

export function toggleVideo() {
  if (localTracks.videoTrack._enabled) 
    localTracks.videoTrack.setEnabled(false);
  else
    localTracks.videoTrack.setEnabled(true);
};

export function toggleMicrophone() {
  if (localTracks.audioTrack._enabled) 
    localTracks.audioTrack.setEnabled(false);
  else
    localTracks.audioTrack.setEnabled(true);
};